<!--阮耀科-满堂支架 页面-->

<template>
  <div>
    <div id="top_font">
      满堂支架设计计算
    </div>
    <div style="display: flex;margin-top: 70px">
      <div style="width: 600px;margin-left: 200px;margin-top: 30px">
        <el-form :model="form" label-width="120px">
          <el-tag class="ml-2" style="margin-left: 300px;margin-bottom: 30px">构件参数</el-tag>
          <el-form-item label="外径(cm)" prop="wj">
            <el-input v-model="form.wj" />
          </el-form-item>
          <el-form-item label="壁厚(cm)" prop="bh">
            <el-input v-model="form.bh" />
          </el-form-item>
          <el-form-item label="弹性模量(GPa)" prop="tx">
            <el-input v-model="form.tx" />
          </el-form-item>
          <el-form-item label="容许应力(MPa)" prop="rx">
            <el-input v-model="form.rx" />
          </el-form-item>
          <el-form-item label="杆件约束系数" prop="gj">
            <el-input v-model="form.gj" />
          </el-form-item>
        </el-form>
      </div>

      <div style="width: 600px;margin-left: 200px">
        <el-tag class="ml-2" style="margin-left: 300px;margin-bottom: 30px">杆件内力计算</el-tag>
        <el-form :model="form" label-width="180px">
          <el-form-item label="杆件自由长度选取(cm)" prop="gjzy">
            <el-input v-model="form.gjzy" />
          </el-form-item>
          <el-form-item label="钢管新旧折减系数" prop="gg">
            <el-input v-model="form.gg" />
          </el-form-item>
          <el-form-item label="钢管重复利用折减系数" prop="cf">
            <el-input v-model="form.cf" />
          </el-form-item>
          <el-form-item label="杆件材质屈服强度(MPa)" prop="cz">
            <el-input v-model="form.cz" />
          </el-form-item>
          <el-form-item label="立杆线路方向间距(m)" prop="lj">
            <el-input v-model="form.lj" />
          </el-form-item>
          <el-form-item label="支撑构件横截面积(㎡)" prop="zc">
            <el-input v-model="form.zc" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="display: flex">
      <div style="width: 600px;margin-left: 200px;margin-top: 30px">
        <el-tag class="ml-2" style="margin-left: 300px;margin-bottom: 30px">杆件分配</el-tag>
        <el-form :model="form" label-width="120px">
          <el-form-item label="砼容重(KN/m³)" prop="trz">
            <el-input v-model="form.trz" />
          </el-form-item>
          <el-form-item label="支架自重(KN/m)" prop="zjzz">
            <el-input v-model="form.zjzz" />
          </el-form-item>
          <el-form-item label="材料自重(KN/m)" prop="clzz">
            <el-input v-model="form.clzz" />
          </el-form-item>
          <el-form-item label="人机活载(KN/m)" prop="rjhz">
            <el-input v-model="form.rjhz" />
          </el-form-item>
          <el-form-item label="其他荷载(KN/m)" prop="qt">
            <el-input v-model="form.qt" />
          </el-form-item>
        </el-form>
      </div>

      <div style="margin-top: 150px;margin-left: 550px">
        <el-button type="primary" @click="getCount()">提交计算</el-button>
        <br>
        <el-button type="info" style="margin-top: 100px" @click="cleanCount()">重置计算</el-button>
      </div>
    </div>

<!--    明细表-->
    <div>
      <vxe-modal v-model="mx" width="1700" height="650" :title="Titlename" show-zoom resize remember>
        <div style="margin-bottom: 10px;text-align: center">
          构件参数
        </div>
        <div style="margin-left: 300px">

            <div style="display: flex">
              <div style="margin-right: 50px">
                <div style="text-align: center">
                  截面参数
                </div>
                <div style="display: flex">
                  <div style="margin-right: 10px">
                    <div>
                      外径(cm)
                    </div>
                    <div style="text-align: center">
                      {{ form.wj }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      壁厚(cm)
                    </div>
                    <div style="text-align: center">
                      {{ form.bh }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      惯性矩(cm4)
                    </div>
                    <div style="text-align: center">
                      {{ gxj }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      截面积(cm2)
                    </div>
                    <div style="text-align: center">
                      {{ jmj }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      回转半径(cm)
                    </div>
                    <div style="text-align: center">
                      {{ hzbj }}
                    </div>
                  </div>
                </div>
              </div>


              <div>
                <div style="text-align: center">
                  力学参数
                </div>
                <div style="display: flex">
                  <div style="margin-right: 10px">
                    <div>
                      弹性模量（GPa）
                    </div>
                    <div style="text-align: center">
                      {{ form.tx }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      容许应力（MPa）
                    </div>
                    <div style="text-align: center">
                      {{ form.rx }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      容许长细比
                    </div>
                    <div style="text-align: center">
                      {{ rxcxb }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      杆件约束系数
                    </div>
                    <div style="text-align: center">
                      {{ form.gj }}
                    </div>
                  </div>
                  <div style="margin-right: 10px">
                    <div>
                      杆件最大自由长度（cm）
                    </div>
                    <div style="text-align: center">
                      {{ gjzdzyc }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


<!--        杆件内力计算-->
        <div style="margin-bottom: 10px;text-align: center;margin-top: 100px">
          杆件内力计算
        </div>
        <div style="margin-left: 100px">

          <div style="display: flex">
            <div style="margin-right: 50px">
              <div style="display: flex">
                <div style="margin-right: 10px">
                  <div>
                    杆件自由长度选取（cm）
                  </div>
                  <div style="text-align: center">
                    {{ form.gjzy }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    计算长细比
                  </div>
                  <div style="text-align: center">
                    {{ jscxb }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    钢管新旧折减系数
                  </div>
                  <div style="text-align: center">
                    {{ form.gg }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    钢管重复利用折减系数
                  </div>
                  <div style="text-align: center">
                    {{ form.cf }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    杆件材质屈服强度(MPa)
                  </div>
                  <div style="text-align: center">
                    {{ form.cz }}
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div style="display: flex">
                <div style="margin-right: 10px">
                  <div>
                    b类截面换算长细比
                  </div>
                  <div style="text-align: center">
                    {{ bljmhs }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    轴心受压长细比折减系数
                  </div>
                  <div style="text-align: center">
                    {{ zxsy }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    单根杆件承压力（KN）
                  </div>
                  <div style="text-align: center">
                    {{ dggj }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    立杆线路方向间距（m）
                  </div>
                  <div style="text-align: center">
                    {{ form.lj }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    支撑构件横截面积（㎡）
                  </div>
                  <div style="text-align: center">
                    {{ form.zc }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<!--        杆件分配-->
        <div style="margin-bottom: 10px;text-align: center;margin-top: 100px">
          杆件分配
        </div>
        <div style="margin-left: 100px">

          <div style="display: flex">
            <div style="margin-right: 50px">
              <div style="display: flex">
                <div style="margin-right: 10px">
                  <div>
                    砼容重（KN/m³）
                  </div>
                  <div style="text-align: center">
                    {{ form.trz }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    支架自重（KN/m）
                  </div>
                  <div style="text-align: center">
                    {{ form.zjzz }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    材料自重（KN/m）
                  </div>
                  <div style="text-align: center">
                    {{ form.clzz }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    人机活载（KN/m）
                  </div>
                  <div style="text-align: center">
                    {{ form.rjhz }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    其他荷载（KN/m）
                  </div>
                  <div style="text-align: center">
                    {{ form.qt }}
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div style="display: flex">
                <div style="margin-right: 10px">
                  <div>
                    线路方向均布荷载（KN/m）
                  </div>
                  <div style="text-align: center">
                    {{ xlfx }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    单个横截面承载力
                  </div>
                  <div style="text-align: center">
                    {{ dghjm }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    横截面脚手架排数
                  </div>
                  <div style="text-align: center">
                    {{ hjmjsj }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    面积分块数
                  </div>
                  <div style="text-align: center">
                    {{ mjfks }}
                  </div>
                </div>
                <div style="margin-right: 10px">
                  <div>
                    每排脚手架支撑构件截面积（㎡）
                  </div>
                  <div style="text-align: center">
                    {{ mpjs }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "mtzjss",
  data(){
    return{
      mx: false,
      Titlename: '满堂支架设计计算结果',
      form:{
        wj: '',
        bh: '',
        tx: '',
        rx: '',
        gj: '',
        gjzy: '',
        gg: '',
        cf: '',
        cz: '',
        lj: '',
        zc: '',
        trz: '',
        zjzz: '',
        clzz: '',
        rjhz: '',
        qt: ''
      },
      gxj: '',
      jmj: '',
      hzbj: '',
      rxcxb: '',
      gjzdzyc: '',
      jscxb: '',
      bljmhs: '',
      zxsy: '',
      dggj: '',
      xlfx: '',
      dghjm: '',
      hjmjsj: '',
      mjfks: '',
      mpjs: ''
    }
  },
  created() {

  },
  methods:{
    getCount(){
      if (this.form.wj === '' || this.form.bh === '' || this.form.tx === '' || this.form.rx === ''
          || this.form.gj === '' || this.form.gjzy === '' || this.form.gg === '' || this.form.cf === ''
          || this.form.cz === '' || this.form.lj === '' || this.form.zc === '' || this.form.trz === ''
          || this.form.zjzz === '' || this.form.clzz === '' || this.form.rjhz === '' || this.form.qt === ''){
        ElMessage.error('数据有误，请检查！')
      }else {
        this.axios.post('/other/mtcount', (response) =>  {
          this.mx = true;
          this.gxj = response.obj.gxj;
          this.jmj = response.obj.jmj;
          this.hzbj = response.obj.hzbj;
          this.rxcxb = response.obj.rxcxb;
          this.gjzdzyc = response.obj.gjzdzyc;
          this.jscxb = response.obj.jscxb;
          this.bljmhs = response.obj.bljmhs;
          this.zxsy = response.obj.zxsy;
          this.dggj = response.obj.dggj;
          this.xlfx = response.obj.xlfx;
          this.dghjm = response.obj.dghjm;
          this.hjmjsj = response.obj.hjmjsj;
          this.mjfks = response.obj.mjfks;
          this.mpjs = response.obj.mpjs;


        },this.form)
      }
    },
    cleanCount(){
      this.form.wj = '';
      this.form.bh = '';
      this.form.tx = '';
      this.form.rx = '';
      this.form.gj = '';
      this.form.gjzy = '';
      this.form.gg = '';
      this.form.cf = '';
      this.form.cz = '';
      this.form.lj = '';
      this.form.zc = '';
      this.form.trz = '';
      this.form.zjzz = '';
      this.form.clzz = '';
      this.form.rjhz = '';
      this.form.qt = '';
    }
  }
}
</script>

<style scoped>
#top_font {
width: 100%;
  font-size: 25px;
  font-family: '苹方', serif;
  font-weight: bolder;
  text-align: center;
  margin-top: 20px
}
</style>